<template>
  <MRow
    :gutter="0"
    :class="{
      'bg-neutral-lightest11': isDone && markBgColor,
      'task-closed': isDone && markBgColor,
    }"
    class="task-item"
  >
    <MCol>
      <MRow>
        <MCol>
          <div class="heading flex flex-1 items-center">
            <h5 class="text-primary flex items-start flex-1">
              <div class="flex-1"> {{ task.name }}: {{ task.subject }} </div>
            </h5>
            <div class="flex items-end">
              <MTooltip v-if="allowEdit && !disabled && !isDone">
                <template v-slot:trigger>
                  <a class="text-neutral" @click="$emit('edit', task)">
                    <MIcon
                      :id="`edit-btn-${task.name}:${task.subject}`"
                      name="pencil"
                      class="text-neutral"
                    />
                  </a>
                </template>
                {{ $t('edit') }}
              </MTooltip>
              <FlotoDeleteBtn
                v-if="allowTaskDeleteAction"
                :id="`delete-btn-${task.name}:${task.subject}`"
                class="mx-1"
                :message="$t('confirm_delete_item', { item: $tc('task') })"
                @confirm="$emit('remove')"
              />
              <a v-if="isDone" class="cursor-default ml-2">
                <div class="bg-secondary-green p-1 rounded flex items-center">
                  <MIcon name="check" class="text-white" />
                </div>
              </a>
              <MTooltip
                v-else-if="
                  (overDue !== false || dueIn !== false) && !isInActiveTask
                "
              >
                <template v-slot:trigger>
                  <a class="cursor-default">
                    <div
                      class="p-1 rounded flex items-center ml-2"
                      :class="{
                        'bg-secondary-red': overDue,
                        'bg-secondary-green': !overDue,
                      }"
                    >
                      <MIcon name="clock" class="text-white" />
                    </div>
                  </a>
                </template>
                {{ overDue || dueIn }} <br />
                ({{ task.timeRange.endTime | datetime }})
              </MTooltip>
            </div>
          </div>
        </MCol>
      </MRow>
      <MRow class="mb-3" :gutter="0">
        <MCol :size="3">
          <FlotoStatusPicker
            :value="task.statusId"
            module-name="task"
            :disabled="isDisabledUserToReopenClosedTasks"
            :allowed-status="
              isProjectMilestoneTask ? ['open', 'closed', 'cancelled'] : []
            "
            @change="handleChange({ statusId: $event })"
          >
            <template v-slot:trigger="slotData">
              <FlotoDropdownTrigger
                type="secondary"
                v-bind="slotData"
                :lable="$t('status')"
                icon-name="status"
                :disabled="disabled"
              />
            </template>
          </FlotoStatusPicker>
        </MCol>
        <MCol :size="3">
          <FlotoPriorityPicker
            :value="task.priorityId"
            :disabled="disabled || detailsDisabled"
            @change="handleChange({ priorityId: $event })"
          >
            <template v-slot:trigger="slotData">
              <FlotoDropdownTrigger
                type="secondary"
                v-bind="slotData"
                :lable="$t('priority')"
                icon-name="priority"
                :disabled="disabled || detailsDisabled"
              />
            </template>
          </FlotoPriorityPicker>
        </MCol>
        <MCol :size="3">
          <!-- <MIcon name="file-check" class="mr-4 text-neutral-light" size="2x" /> -->
          <FlotoTaskTypePicker
            :value="task.taskTypeId"
            :disabled="disabled || isProjectMilestoneTask || detailsDisabled"
            @change="handleChange({ taskTypeId: $event })"
          >
            <template v-slot:trigger="slotData">
              <FlotoDropdownTrigger
                type="secondary"
                v-bind="slotData"
                :lable="`${$tc('task_type')}`"
                icon-name="file-check"
                :disabled="
                  disabled || isProjectMilestoneTask || detailsDisabled
                "
              />
            </template>
          </FlotoTaskTypePicker>
        </MCol>
        <MCol :size="3">
          <FlotoTechnicianGroupPicker
            :value="task.groupId"
            :disabled="disabled || detailsDisabled"
            @change="handleChange({ groupId: $event })"
          >
            <template v-slot:trigger="slotData">
              <FlotoDropdownTrigger
                type="secondary"
                v-bind="slotData"
                :lable="`${$tc('technician_group')}`"
                icon-name="users"
                :disabled="disabled || detailsDisabled"
              />
            </template>
          </FlotoTechnicianGroupPicker>
        </MCol>
      </MRow>
      <MRow class="mb-3" :gutter="0">
        <MCol :size="3">
          <FlotoTechnicianPicker
            :value="task.technicianId"
            :size="35"
            :disabled="disabled || detailsDisabled"
            :group-id="task.groupId"
            change-technician-on-group-change
            @change="handleChange({ technicianId: $event })"
          >
            <template v-slot:trigger="slotData">
              <FlotoDropdownTrigger
                type="secondary"
                v-bind="slotData"
                :lable="`${$tc('assignee')}`"
                icon-name="vector"
                :disabled="disabled || detailsDisabled"
                style="margin-right: 0"
              >
                <template v-slot:icon>
                  <FlotoUserAvatar
                    :size="25"
                    :avatar="(slotData.currentItem || {}).avatar"
                    :style="{
                      minWidth: `${24}px`,
                    }"
                  />
                </template>
              </FlotoDropdownTrigger>
            </template>
          </FlotoTechnicianPicker>
        </MCol>
        <MCol :size="6">
          <FlotoTriggerView
            icon-name="calendar-alt"
            type="secondary"
            :lable="`${$t('start')} ${$t('date')} - ${$t('end')} ${$t('date')}`"
          >
            <template v-slot:value>
              <div class="">
                <template
                  v-if="
                    (task.timeRange || {}).startTime ||
                    (task.timeRange || {}).endTime
                  "
                >
                  <span>{{ (task.timeRange || {}).startTime | datetime }}</span>
                  <span
                    v-if="
                      (task.timeRange || {}).startTime &&
                      (task.timeRange || {}).endTime
                    "
                    class="text-center my-1"
                  >
                    -
                  </span>
                  <span>{{ task.timeRange.endTime | datetime }}</span>
                </template>
                <template v-else> --- </template>
              </div>
            </template>
          </FlotoTriggerView>
        </MCol>
      </MRow>
      <MRow
        v-if="task.moduleName === $constants.PROJECT"
        class="mb-3"
        :gutter="0"
      >
        <MCol :size="3">
          <FlotoTriggerView
            icon-name="check-circle"
            type="secondary"
            :lable="`${$t('completion')}`"
            :value="`${task.completion} %`"
          />
        </MCol>
        <MCol :size="3">
          <FlotoTriggerView
            icon-name="clock"
            type="secondary"
            :lable="`${$t('duration')} (${$tc('days', 2)})`"
            :value="`${task.duration} ${$tc('days', task.duration)}`"
          />
        </MCol>
        <MCol :size="3">
          <FlotoTriggerView
            icon-name="hourglass"
            type="secondary"
            :lable="`${$t('estimates')} (${$tc('hours', 2)})`"
            :value="
              task.estimatedTime
                ? `${task.estimatedTime} ${$tc('hours', 2)}`
                : '---'
            "
          />
        </MCol>
      </MRow>
      <slot name="task-actions">
        <MRow>
          <MCol>
            <template v-if="!disabled">
              <FlotoDeleteBtn
                v-if="!isDone"
                placement="bottomLeft"
                :disabled="detailsDisabled"
                :message="$t('confirm', { message: $t('task_is_done') })"
                :show-tooltip="false"
                @confirm="handleChangeStatus('closed')"
              >
                <MButton id="done-btn" class="mr-2" :disabled="detailsDisabled">
                  {{ $t('done') }}
                </MButton>
              </FlotoDeleteBtn>
              <MButton
                v-else
                id="reopen-btn"
                class="mr-2"
                :disabled="isDisabledUserToReopenClosedTasks"
                @click="handleChangeStatus('open')"
              >
                {{ $t('reopen') }}
              </MButton>
            </template>
            <FlotoTaskDrawer
              v-if="!isInActiveTask"
              :default-task="task"
              :disabled="disabled"
              :details-disabled="detailsDisabled"
              :parent-id="task.parentId"
              :module-name="task.moduleName"
              :hide-conversation="isInActiveTask"
              :hide-work="isInActiveTask"
              @remove="$emit('remove', task)"
              @update="$emit('update', $event)"
            >
              <template v-slot:trigger="{ open }">
                <MButton
                  id="view-details-btn"
                  variant="default"
                  class="mr-2"
                  @click="open"
                >
                  {{ $t('view') }} {{ $t('details') }}
                </MButton>
              </template>
            </FlotoTaskDrawer>
          </MCol>
        </MRow>
      </slot>
    </MCol>
  </MRow>
</template>

<script>
import Pick from 'lodash/pick'
import Moment from 'moment'
import { StatusComputed } from '@state/modules/status'
import { TaskComputed } from '@state/modules/task'
import { PreferenceComputed } from '@state/modules/preference'
export default {
  name: 'TaskDetails',
  props: {
    task: { type: Object, required: true },
    markBgColor: { type: Boolean, default: false },
    allowEdit: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    allowDelete: { type: Boolean, default: false },
    isInActiveTask: { type: Boolean, default: false },
    detailsDisabled: { type: Boolean, default: false },
  },
  computed: {
    ...StatusComputed,
    ...TaskComputed,
    ...Pick(PreferenceComputed, [
      'allowUserToReopenClosedTasks',
      'allowUserToDeleteTasks',
    ]),
    isProjectMilestoneTask() {
      const taskTypeId = (
        this.taskTypeOptions.find((t) => t.systemName === 'Milestone') || {}
      ).id
      if (
        this.task.moduleName === this.$constants.PROJECT &&
        this.task.taskTypeId === taskTypeId
      ) {
        return true
      }
      return false
    },
    isDone() {
      const closedStatus = this.taskFindStatus('closed')
      if (closedStatus) {
        return closedStatus.id === this.task.statusId
      }
      return false
    },
    overDue() {
      if (this.isDone) {
        return false
      }
      if (!(this.task.timeRange || {}).endTime) {
        return false
      }
      const diff = Moment().unix() * 1000 - this.task.timeRange.endTime
      if (diff > 0) {
        // return `${this.$t('overdue')} ${this.$t('by')} ${Moment.duration(
        //   diff,
        //   'millisecond'
        // ).humanize()}`
        return `${this.$t('overdue')} ${this.$t(
          'by'
        )} ${this.$options.filters.duration(diff)}`
      }
      return false
    },
    dueIn() {
      if (this.isDone) {
        return false
      }
      if (!(this.task.timeRange || {}).endTime) {
        return false
      }
      const diff = Math.abs(
        Moment().unix() * 1000 - this.task.timeRange.endTime
      )
      // return `${this.$t('due_in')} ${Moment.duration(
      //   diff,
      //   'millisecond'
      // ).humanize()}`
      return `${this.$t('due_in')} ${this.$options.filters.duration(diff)}`
    },
    isDisabledUserToReopenClosedTasks() {
      const currentTask = this.taskStatus.find(
        (f) => f.key === this.task.statusId
      )
      if (this.disabled) {
        return true
      }
      if (this.detailsDisabled) {
        return true
      }
      if (
        currentTask &&
        !this.allowUserToReopenClosedTasks &&
        (currentTask.systemName || '').toLowerCase() === 'closed'
      ) {
        return true
      }
      return false
    },
    allowTaskDeleteAction() {
      if (this.allowUserToDeleteTasks) {
        return !this.disabled && this.allowDelete && !this.detailsDisabled
      }
      return false
    },
  },
  methods: {
    handleChange(change) {
      this.$emit('update', { ...this.task, ...change })
    },
    handleChangeStatus(status) {
      const change = {
        statusId: this.taskFindStatus(status).id,
      }
      this.handleChange(change)
    },
  },
}
</script>

<style lang="less" scoped>
.heading {
  position: relative;
  padding-right: 0;

  .mark-icon {
    position: absolute;
    top: 0;
    right: 5px;
  }
}

.task-item {
  // background: var(--page-background-color);
  // border: 1px solid var(--border-color);
  // &.task-closed {
  //   border: 1px solid var(--secondary-green);
  // }
}
</style>
